import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import HubspotForm from 'react-hubspot-form';
import { Container } from 'components/layout';

import s from './NewsletterCta.scss';
import './hbspt.css';

const query = graphql`
  query NewsletterCta {
    newsletter: contentfulNewsletterCta {
      headline
      subheadline
      hasSubscribed
      buttonText
      backgroundColour
      image {
        ...image
      }
    }
  }
`;

class NewsletterCta extends React.Component {

  static propTypes = {
    data: PropTypes.object,
  }

  render() {
    const { data: { newsletter } } = this.props;

    return (
      <div className={s.wrapper}>
        <img
          role='presentation'
          className={s.image}
          src={newsletter.image.file.url}
        />
        <Container className={s.container}>
          <div className={s.main}>
            <h1 className={s.headline}>{newsletter.headline}</h1>
            <span className={s.subheadline}>{newsletter.subheadline}</span>
            <div className={s.hubspotForm}>
              <HubspotForm
                portalId="5092845"
                cssClass="newsletter stacked"
                formId="482c7135-b3b9-43f1-8590-cdd862aaf4a9"
                onSubmit={() => undefined}
                loading={<div>Loading...</div>}
              />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default () => (
  <StaticQuery
    query={query}
    render={data => (
      <NewsletterCta
        data={data}
      />
    )}
  />
);
