import { graphql } from 'gatsby';
import Blog from 'routes/blog/Blog';

export default Blog;

export const query = graphql`
  fragment blogArticle on ContentfulBlogArticle {
    title
    ctaUrl: slug
    heroHeading
    heading
    createdDate(formatString: "MMMM Do, YYYY")
    heroImage {
      ...image
    }
    categoryNew {
      name
    }
  }

  query Blog($category: String!, $skip: Int!, $limit: Int!) {
    page: contentfulPageBlog {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      heroTitle
      heroBody
      slug
      metaDescription
      featuredBlogArticle {
        ...blogArticle
      }
    }

    all: allContentfulBlogArticle(
      sort: { fields: createdDate, order: DESC },
      filter: { slug: { regex: "/^(?!placeholder-).*$/" } },
    ) {
      edges {
        node {
          ...blogArticle
        }
      }
    }

    unfiltered: allContentfulBlogArticle(
      limit: $limit,
      skip: $skip,
      sort: { fields: createdDate, order: DESC },
      filter: { slug: { regex: "/^(?!placeholder-).*$/" } },
    ) {
      edges {
        node {
          ...blogArticle
        }
      }
    }

    filtered: allContentfulBlogArticle(
      filter: {
        categoryNew : { name : { eq: $category } }
        slug: { regex: "/^(?!placeholder-).*$/" }
      },
      limit: $limit,
      skip: $skip,
      sort: { fields: createdDate, order: DESC },
    ) {
        edges {
          node {
            ...blogArticle
          }
        }
    }

    allContentfulBlogCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
