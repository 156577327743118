import React, { PureComponent } from 'react';

import Button from 'components/button';
import Image from 'components/image';

import {
  cardCover,
  cardTitle,
  cardSubTitle,
  cardBodyText,
  cardUrl,
  cardNoRatio,
  cardTransition,
} from './proptypes';

import s from './ContentCard.scss';

export default class ContentCard extends PureComponent {

  static propTypes = {
    cover: cardCover,
    title: cardTitle,
    subTitle: cardSubTitle,
    bodyText: cardBodyText,
    ctaUrl: cardUrl,
    noRatio: cardNoRatio,
    transition: cardTransition,
  };

  render() {
    const {
      cover,
      title,
      subTitle,
      bodyText,
      ctaUrl,
      noRatio,
      transition,
    } = this.props;

    const IMAGE_WIDTH = 290;

    return (
      <figure className={s.contentCard}>
        {cover &&
          <div
            className={s(
              s.contentCard__imageWrap,
              {
                [s.contentCard__imageWrapNoRatio]: noRatio === true,
                [s.contentCard__imageWrapWithRatio]: noRatio === false,
              },
            )}
          >
            <div className={s.contentCard__image}>
              <Image
                src={`${cover.src}?w=${IMAGE_WIDTH}`}
                src2x={`${cover.src}?w=${IMAGE_WIDTH * 2}`}
                alt={cover.alt}
                width={cover.width}
                height={cover.height}
                transition={transition}
              />
            </div>
          </div>
        }
        <figcaption className={s.contentCard__caption}>
          <div>
            {title &&
              <h3 className={s.contentCard__title}>{title}</h3>
            }
            {subTitle &&
              <span className={s.contentCard__subTitle}>{subTitle}</span>
            }
            {bodyText &&
              <p className={s.contentCard__bodyText}>{bodyText}</p>
            }
          </div>
          {ctaUrl &&
            <div className={s.contentCard__cta}>
              <Button to={ctaUrl} color="transparent" arrow />
            </div>
          }
        </figcaption>
      </figure>
    );
  }
}
