import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import LazyLoad from 'react-lazyload';

import ContentCard from './ContentCard';
import FeaturedCard from './FeaturedCard';

import s from './ContentGrid.scss';

import {
  cardShape,
  cardNoRatio,
  cardTransition,
} from './proptypes';

export default class ContentGrid extends PureComponent {

  static propTypes = {
    featuredCard: cardShape,
    cards: PropTypes.arrayOf(cardShape),
    noRatio: cardNoRatio,
    shouldAnimate: PropTypes.bool,
    transition: cardTransition,
    showFeaturedCard: PropTypes.bool,
  }

  static defaultProps = {
    featuredCard: null,
    cards: [],
    showFeaturedCard: true,
  }

  render() {
    const {
      featuredCard,
      cards,
      noRatio,
      transition,
      shouldAnimate,
      showFeaturedCard,
    } = this.props;

    const cardsCount = cards.length;

    const getSubTitle = (obj) => {
      const createdDate = _get(obj, 'createdDate');
      const subTitle = _get(obj, 'subTitle');

      return subTitle || createdDate;
    };

    return (
      <Fragment>
        <div className={s.contentGrid}>
          {featuredCard && showFeaturedCard &&
            <div className={s.contentGrid__row}>
              <FeaturedCard
                shouldAnimate={shouldAnimate}
                title={_get(featuredCard, 'title')}
                subTitle={getSubTitle(featuredCard)}
                ctaUrl={_get(featuredCard, 'ctaUrl')}
                cover={{
                  src: _get(featuredCard, 'heroImage.file.url'),
                  alt: _get(featuredCard, 'heroImage.description'),
                  width: _get(featuredCard, 'heroImage.file.details.image.width'),
                  height: _get(featuredCard, 'heroImage.file.details.image.height'),
                }}
              />
            </div>
          }
          <div className={s.contentGrid__row}>
            {cards && cards.map((card, i) => (
              <div
                key={_get(card, 'title') + i}
                className={s(
                  s.contentGrid__cardBlock,
                  {
                    [s.contentGrid__cardBlockHalf]: cardsCount <= 2,
                    [s.contentGrid__cardBlockThird]: cardsCount >= 3,
                  },
                )}
              >
                <LazyLoad once offset={500}>
                  <ContentCard
                    title={_get(card, 'title')}
                    subTitle={getSubTitle(card)}
                    bodyText={_get(card, 'bodyText')}
                    ctaUrl={_get(card, 'ctaUrl')}
                    noRatio={noRatio}
                    transition={transition}
                    cover={{
                      src: _get(card, 'heroImage.file.url'),
                      alt: _get(card, 'heroImage.description'),
                      width: _get(card, 'heroImage.file.details.image.width'),
                      height: _get(card, 'heroImage.file.details.image.height'),
                    }}
                  />
                </LazyLoad>
              </div>
            ))}
          </div>
        </div>
      </Fragment>
    );
  }
}
