import React, { PureComponent } from 'react';

import Button from 'components/button';
import Image from 'components/image';

import {
  cardCover,
  cardTitle,
  cardSubTitle,
  cardBodyText,
  cardUrl,
  cardShouldAnimate,
} from './proptypes';

import s from './FeaturedCard.scss';

export default class FeaturedCard extends PureComponent {

  static propTypes = {
    cover: cardCover,
    title: cardTitle,
    subTitle: cardSubTitle,
    bodyText: cardBodyText,
    ctaUrl: cardUrl,
    shouldAnimate: cardShouldAnimate,
  };

  render() {
    const {
      cover,
      title,
      subTitle,
      bodyText,
      ctaUrl,
      shouldAnimate,
    } = this.props;

    const IMAGE_WIDTH = 495;

    return (
      <figure className={s.featuredCard}>
        {cover &&
          <div className={s.featuredCard__imageWrap}>
            <div className={s.featuredCard__image}>
              <Image
                src={`${cover.src}?w=${IMAGE_WIDTH}`}
                src2x={`${cover.src}?w=${IMAGE_WIDTH * 2}`}
                alt={cover.alt}
                width={cover.width}
                height={cover.height}
                transition={shouldAnimate && 'wipe'}
              />
            </div>
          </div>
        }
        <figcaption className={s.featuredCard__caption}>
          <div>
            {title &&
              <h2 className={s.featuredCard__title}>{title}</h2>
            }
            {subTitle &&
              <span className={s.featuredCard__subTitle}>{subTitle}</span>
            }
            {bodyText &&
              <p className={s.featuredCard__bodyText}>{bodyText}</p>
            }
          </div>
          {ctaUrl &&
            <div className={s.featuredCard__cta}>
              <Button to={ctaUrl} color="transparent">Read now</Button>
            </div>
          }
        </figcaption>
      </figure>
    );
  }
}
