import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import extractMeta from 'utils/extract-meta';
import { withUIContext } from 'context/ui';
import HeroIntro from 'components/hero-intro';
import ContentGrid from 'components/content-grid';
import SEO from 'components/seo';
import { Container } from 'components/layout';
import { DocumentNavigation, DocumentNavigationItem } from 'components/document-group';

import NewsletterCta from 'components/newsletter-cta';
import Pagination from 'components/pagination';
import s from './Blog.scss';

class Blog extends PureComponent {

  static propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object,
    location: PropTypes.object,
  }

  render() {
    const {
      page,
      all,
      unfiltered: { edges: unfilteredArticles },
      filtered,
      allContentfulBlogCategory: { edges: categories },
    } = this.props.data;

    // console.log(all);

    let renderedArticles = unfilteredArticles;

    const {
      currentPage,
      numberOfPages,
      pathBase,
      category: currentCategory,
    } = this.props.pageContext;

    // console.log('Current category: ', page.slug);

    const shouldAnimate = currentPage === 1;
    const { pathname } = this.props.location;

    // TODO
    if (filtered && filtered.edges && filtered.edges.length > 0) {
      const { edges: filteredArticles } = filtered;

      renderedArticles = filteredArticles;
      // console.log(filteredArticles);
    }

    return (
      <div>
        <SEO
          pathname={pathname}
          article
          htmlAttributes={{ class: 'smooth-scroll' }}
          {...extractMeta(page)}
        />
        <HeroIntro
          heading={page.heroTitle}
          text={page.heroBody}
          cancelHr
          shouldAnimate={shouldAnimate}
        />
        <Container>
          <div className={s.flex}>
            <div className={s.left}>
              <DocumentNavigation>
                <DocumentNavigationItem
                  key={0}
                  index={0}
                  text="All"
                  href={page.slug}
                  active={!currentCategory}
                />
                {
                  categories
                    .filter((categoryItem) => {
                      const hasAtLeastOneItem =
                        all.edges.reduce((accumulator, currentValue) => {
                          const isSameCategory = currentValue.node.categoryNew.name === categoryItem.node.name;

                          return (accumulator || isSameCategory);
                        }, false);

                      return hasAtLeastOneItem;
                    })
                    .map((categoryItem, i) =>
                    // console.log('Category info: ', category);
                     (
                       <DocumentNavigationItem
                         href={`${page.slug}/${categoryItem.node.slug}`}
                         key={i + 1}
                         index={i + 1}
                         text={categoryItem.node.name}
                         active={categoryItem.node.name === currentCategory}
                       />
                    ))
                }
              </DocumentNavigation>
              <Pagination
                currentPage={currentPage}
                numberOfPages={numberOfPages}
                pathBase={pathBase}
              />
            </div>
            <ContentGrid
              cards={renderedArticles.map(article => article.node)}
              showFeaturedCard={!(filtered && filtered.edges && filtered.edges.length > 0)}
              featuredCard={page.featuredBlogArticle}
              shouldAnimate={shouldAnimate}
            />
          </div>
        </Container>
        <NewsletterCta />
      </div>
    );
  }
}
export default withUIContext(Blog);
