import PropTypes from 'prop-types';

export const cardCover = PropTypes.shape({
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
});

export const cardTitle = PropTypes.string;
export const cardSubTitle = PropTypes.string;
export const cardBodyText = PropTypes.string;
export const cardUrl = PropTypes.string;
export const cardNoRatio = PropTypes.bool;
export const cardTransition = PropTypes.string;
export const cardShouldAnimate = PropTypes.bool;

export const cardShape = PropTypes.shape({
  cover: cardCover,
  title: cardTitle,
  subTitle: cardSubTitle,
  bodyText: cardBodyText,
  ctaUrl: cardUrl,
  noRatio: cardNoRatio,
  shouldAnimate: cardShouldAnimate,
});
