import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Clickable from 'components/clickable'
import ArrowSmall from 'assets/svg/icons/arrow-small.svg'
import s from './Pagination.scss'

class ArrowButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  }

  static defaultProps = {
    direction: 'left',
  }

  render() {
    const { className, direction } = this.props

    let degree = '0deg'

    switch (direction) {
      case 'top':
        degree = '90deg'
        break
      case 'right':
        degree = '180deg'
        break
      case 'bottom':
        degree = '270deg'
        break
    }

    return (
      <span
        className={className}
        style={{ transform: `rotate(${degree})` }}
      >
        <ArrowSmall />
      </span>
    )
  }
}

export default class Pagination extends React.Component {
  static propTypes = {
    currentPages: PropTypes.number,
    numberOfPages: PropTypes.number,
    className: PropTypes.string
  }

  render() {
    const { 
      currentPage, 
      numberOfPages,
      pathBase,
      className 
    } = this.props;

    const hasPagination = numberOfPages > 1;

    let skip = false;
    // let maxLinks = 5;

    return (
      <div className={className}>
        {hasPagination && (
          <div className={s.pagination}>
            {currentPage > 1 && (
              <Clickable
                to={
                  `${pathBase}` +
                  (currentPage - 1 <= 1 ? '' : `/${currentPage - 1}`)
                }
              >
                <ArrowButton className={s('arrowButton', 'previous')} direction="left" />
              </Clickable>
            )}
            <ul>
              {Array.from({ length: numberOfPages }).map((_, i, initial) => {
                const highestElement = initial.length;
                // console.log('Current page: ', currentPage, ', i: ', i, ', Highest: ', highestElement)

                if(currentPage === i+1) {  // No link for the page I am currently on
                  skip = false;
                  return <li key={i}>{i+1}</li>;
                } else {
                  if(i+2 === currentPage || i === currentPage || i === 0 || i+1 === highestElement) { // Is not the first, not the last nor the element on the left or the right of the current element
                    skip = false;
                    return (
                      <li key={i}>
                        <Clickable
                          to={`${pathBase}` + (i === 0 ? '' : `/${i + 1}`)}>
                            {i+1}
                        </Clickable>
                      </li>
                    );
                  } else {
                      if(skip) {
                        return '' // No elipsis
                      } else {
                        skip = true;  // Only one ellipsis per set
                        return <li key={i}>...</li>
                      }
                  }
                }

                /* 
                return (
                  <li key={i}>
                    {
                      currentPage === i+1 
                      ? (i+1) // No link for the page I am currently on
                      : (i+2 === currentPage || i === currentPage || i === 0 || i+1 === highestElement) // Is not the first, not the last nor the element on the left or the right of the current element
                        ? <Clickable
                          to={`${pathBase}` + (i === 0 ? '' : `/${i + 1}`)}>
                            {i+1}
                          </Clickable>
                        : skip 
                          ? '' // No elipsis
                          : '...' // Elipsis
                    }
                  </li>
                ) 
                */
              })}
            </ul>
            {currentPage < numberOfPages && (
              <Clickable to={`${pathBase}/${currentPage + 1}`}>
                <ArrowButton className={s('arrowButton', 'next')} direction="right" />
              </Clickable>
            )}
          </div>
        )}
      </div>
    )
  }
}
